import React from "react";
import "./App.css";
import Firstpage from "./files";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Ecommerce,Individualproduct } from "./components";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Firstpage />} />
          <Route path="/Shoppingcart" element={<Ecommerce />} />
          <Route path="/Shoppingitem/:id" element={<Individualproduct />} />
          
        </Routes>
      </div>
    </Router>
  );
}

export default App;
